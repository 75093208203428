@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
    height: 100%;
}

.arrow-upward-install {
    animation: MoveUpDown 1.5s linear infinite;
}

@keyframes MoveUpDown {
    0%, 100% {
        top: 10px;
    }
    50% {
        top: 20px;
    }
}